import { useState } from 'react'
import { CheckJoinCode, JoinBusinessWithCode } from '~/api'
import { Button } from '~/components/Common/Button'
import { TextField } from '~/components/Form/TextField'
import Icon from '~/components/Icon'
import { Modal } from '~/components/Modal/Modal'
import $posthog from '~/lib/posthog'
import locale from '~/locale'
import { setCurrentBusiness } from '~/utils'

interface PopupJoinBusinessI {
    show: boolean
    onSave: (value: string) => void
    onClose: () => void
}

export const PopupJoinBusiness = ({ show, onSave, onClose }: PopupJoinBusinessI) => {
    const [field, setField] = useState('')
    const [errorCheck, setErrorCheck] = useState('')
    const [isChecking, setChecking] = useState(false)
    const [errorConfirm, setErrorConfirm] = useState('')
    const [isConfirming, setConfirming] = useState(false)
    const [data, setData] = useState({
        name: ''
    })

    const submit = async () => {
        if (field.length === 0 || isChecking) {
            return
        }

        setChecking(true)
        setErrorCheck('')

        const { data: responseData, error } = await CheckJoinCode(field)

        setChecking(false)

        if (!!error) {
            setErrorCheck(locale.id.code.invalid)
            return
        }

        setData({
            name: responseData || ''
        })
    }

    const confirm = async () => {
        if (field.length === 0 || isConfirming) {
            return
        }

        setConfirming(true)
        setErrorConfirm('')

        const { data: responseData, error } = await JoinBusinessWithCode(field)

        setConfirming(false)

        if (!!error) {
            setErrorConfirm(locale.id.code.invalid)
            $posthog.capture('business_join_submit_confirm', { status: 'failed' })
            return
        }

        if (!!responseData) {
            setCurrentBusiness(responseData.businessId)
            const eventProps = {
                status: 'success',
                business_id: responseData.businessId
            }
            $posthog.capture('business_join_submit_confirm', eventProps)
            window.location.href = '/business/product'
        }
    }

    return (
        <Modal show={show} header={false} size="sm">
            {data.name.length === 0 ? (
                <>
                    <h3 className="font-bold text-lg text-center mb-3 text-neutrals-900">
                        {locale.id.business.join_business.title}
                    </h3>
                    <p className="text-sm text-neutrals-900 mb-3">{locale.id.business.join_business.input_code}</p>
                    <TextField
                        className="mb-6"
                        textAlign="center"
                        width={150}
                        placeholder={locale.id.business.join_business.field_placeholder}
                        value={field}
                        captionVariant={errorCheck.length ? 'error' : undefined}
                        onInput={(value) => setField(value)}
                        captions={errorCheck}></TextField>
                    <div className="flex justify-around">
                        <Button
                            text={locale.id.common.button.cancel}
                            type="error"
                            icon={<Icon name="x-circle-outline" size="lg"></Icon>}
                            onClick={onClose}></Button>
                        <div className="w-5"></div>
                        <Button
                            text={locale.id.common.button.submit}
                            icon={<Icon name="save" size="lg"></Icon>}
                            onClick={submit}
                            disabled={field.length === 0}
                            loading={isChecking}></Button>
                    </div>
                </>
            ) : (
                <>
                    <h3 className="font-bold text-lg text-center mb-3 text-neutrals-900">
                        {locale.id.business.join_business.confirm_title} <b>{data.name}</b>?
                    </h3>
                    <p className="text-sm text-neutrals-900 mb-6">
                        {locale.id.business.join_business.confirm_text} {data.name}
                    </p>
                    {errorConfirm.length > 0 && <p className="text-sm text-red text-center mb-2">{errorConfirm}</p>}
                    <div className="flex justify-around">
                        <Button
                            text={locale.id.common.button.cancel}
                            icon={<Icon name="x-circle-outline" size="lg"></Icon>}
                            type="error"
                            onClick={() => {
                                setData({
                                    name: ''
                                })
                                setField('')
                            }}></Button>
                        <div className="w-5"></div>
                        <Button
                            text={locale.id.common.button.yes}
                            onClick={confirm}
                            icon={<Icon name="check-circle-outline" size="lg"></Icon>}
                            disabled={field.length === 0}
                            loading={isConfirming}></Button>
                    </div>
                </>
            )}
        </Modal>
    )
}
